.app {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: black;
  overflow: hidden;
  display: flex;
}
