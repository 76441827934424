@font-face {
  font-family: 'Funkis A';
  src: url('./fonts/FunkisA-Regular.woff2') format('woff2'),
      url('./fonts/FunkisA-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Funkis A';
  src: url('./fonts/FunkisA-SemiBold.woff2') format('woff2'),
      url('./fonts/FunkisA-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Funkis A';
  src: url('./fonts/FunkisA-Bold.woff2') format('woff2'),
      url('./fonts/FunkisA-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}